import React, { useEffect, useMemo, useState } from "react";
import { Card, Row, Col, Pagination, Empty, Skeleton, Tabs } from "antd";
import Meta from "antd/es/card/Meta";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getEvents } from "../utility/commonApi";
import he from "he";
import Loader from "../views/common/loader";
import Wrapper from "../views/common/commonWrapper";
import moment from "moment";
import clockImg from "../assets/icons/clockIcon.svg";
import calendarImg from "../assets/icons/calendar.svg";
import { fetchImage } from "../utility/commonDownloadS3Image";
import placeholderImage from "../assets/icons/placeholderimage.webp";

function Event() {
  const { TabPane } = Tabs;
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState("upcoming"); // Added state for tabs
  const pageSize = 8;

  // Fetching events using React Query
  const { data, isLoading } = useQuery({
    queryKey: ["events", currentPage],
    queryFn: () =>
      getEvents({ page: currentPage, limit: pageSize, sortOrder: "DESC" }),
    keepPreviousData: true,
  });

  // Memoizing the fetched data
  const eventData = useMemo(() => data?.data?.results ?? [], [data]);
  const totalEvents = data?.data?.totalResults || 0;

  // Separate events into upcoming and past based on the start date
  const upcomingEvents = useMemo(
    () =>
      eventData.filter((event) =>
        moment(event.startDate).isSameOrAfter(moment(), "day")
      ),
    [eventData]
  );

  const pastEvents = useMemo(
    () =>
      eventData.filter((event) =>
        moment(event.startDate).isBefore(moment(), "day")
      ),
    [eventData]
  );

  // Handle tab change
  const onTabChange = (key) => {
    setCurrentTab(key);
    setCurrentPage(1); // Reset pagination when switching tabs
  };

  // Handle page change
  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  // Filter data based on the selected tab
  const filteredEvents = useMemo(() => {
    return currentTab === "upcoming" ? upcomingEvents : pastEvents;
  }, [currentTab, upcomingEvents, pastEvents]);

  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    if (filteredEvents && filteredEvents.length > 0) {
      const loadImages = async () => {
        const urls = {};
        await Promise.all(
          filteredEvents.map((event) => {
            if (event.images && event.images.length > 0) {
              return Promise.all(
                event.images.map(async (image) => {
                  const url = await fetchImage(image.name);
                  urls[event.id] = urls[event.id] || [];
                  urls[event.id].push(url);
                })
              );
            }
          })
        );
        setImageUrls(urls);
      };

      loadImages();
    }
  }, [filteredEvents]);

  const stripHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <Wrapper>
      {/* Tabs for filtering Upcoming and Past events */}
      <Tabs activeKey={currentTab} onChange={onTabChange}>
        <Tabs.TabPane tab="Upcoming Events" key="upcoming" />
        <Tabs.TabPane tab="Past Events" key="past" />
      </Tabs>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Loader - Display while data is loading */}
        {isLoading ? (
          <Loader />
        ) : (
          <div style={{ flex: 1 }}>
            {/* If there are no events, show "No records found" */}
            {filteredEvents.length === 0 ? (
              <div style={{ textAlign: "center", marginTop: "50px" }}>
                <Empty
                  description={
                    currentTab === "upcoming"
                      ? "No Upcoming Events"
                      : "No Past Events"
                  }
                />
              </div>
            ) : (
              <>
                {/* Displaying events in grid */}
                <div style={{ paddingBottom: "80px", marginBottom: "50px" }}>
                  <Row gutter={[{ xs: 16, sm: 16, md: 24, lg: 32 }, 16]}>
                    {filteredEvents.map((event) => {
                      const decodedBody = he.decode(event.body);
                      const strippedBody = stripHtmlTags(decodedBody);

                      // Truncate text if it's too long
                      const truncatedBody =
                        strippedBody.length > 100
                          ? strippedBody.substring(0, 100) + "..."
                          : strippedBody;

                      return (
                        <Col
                          xs={24}
                          sm={12}
                          md={8}
                          lg={6}
                          key={event.id}
                          className="cardColumn"
                        >
                          <Card
                            className="cardContainer"
                            hoverable
                            style={{ width: "100%" }}
                            onClick={() => {
                              const imageSrc =
                                imageUrls[event.id] && imageUrls[event.id][0];

                              navigate(`/detail/event/${event.id}`, {
                                state: { imageSrc },
                              });
                            }}
                            cover={
                              <>
                                {imageUrls && imageUrls[event.id] ? (
                                  <img
                                    className="cardImage"
                                    alt={event.title}
                                    src={
                                      (imageUrls[event.id] &&
                                        imageUrls[event.id][0]) ||
                                      placeholderImage
                                    }
                                  />
                                ) : (
                                  <Skeleton.Image active width={100} />
                                )}
                              </>
                            }
                          >
                            <Meta
                              title={
                                <div className="cardTitle">{event.title}</div>
                              }
                              description={
                                <div className="cardDescription">
                                  <div>{truncatedBody}</div>
                                  {strippedBody.length > 100 && (
                                    <span
                                      className="cardReadMore"
                                      onClick={() => {
                                        const imageSrc =
                                          imageUrls[event.id] &&
                                          imageUrls[event.id][0];

                                        navigate(`/detail/event/${event.id}`, {
                                          state: { imageSrc },
                                        });
                                      }}
                                    >
                                      {" "}
                                      Read more
                                    </span>
                                  )}
                                </div>
                              }
                            />
                            <div className="cardDate">
                              <div className="d-flex flex-column">
                                <div>
                                  <img
                                    src={calendarImg}
                                    width={15}
                                    height={15}
                                    className="me-1"
                                  />
                                  <span>
                                    {`Publish Date: ${moment(
                                      event.startDate
                                    ).format("DD MMM YYYY")}`}
                                  </span>
                                </div>
                                <div>
                                  <img
                                    src={clockImg}
                                    width={15}
                                    height={15}
                                    className="me-1"
                                  />
                                  <span>{`Time: ${event.startTime} - ${event.endTime}`}</span>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </div>

                {/* Pagination */}
                {!isLoading && totalEvents > 0 && (
                  <Card className="pagination-card">
                    <Pagination
                      current={currentPage}
                      pageSize={pageSize}
                      total={filteredEvents.length}
                      onChange={onChangePage}
                      showSizeChanger={false}
                    />
                  </Card>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
}

export default Event;
