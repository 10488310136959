import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import store from "./redux/store";
import "./App.css";
import "./common.scss";
import IntroPage from "./components/introPage";
import NotFound from "./components/404";
import PrivacyPolicy from "./views/policy/privacy-policy";
import RefundPolicy from "./views/policy/refund";
import TermsConditions from "./views/policy/terms";
import CommonDetails from "./views/common/commonDetails";
import Updates from "./components/updates";
import Login from "./components/login";
import Event from "./components/event";
import Notice from "./components/notice";
import SevaList from "./components/sevaList";
import Punyarjak from "./components/punyarjak";
import SevaBooking from "./components/sevaBooking";
import BookingDetail from "./components/bookingDetail";
import AboutUs from "./components/aboutUs";
import Gallery from "./components/galleries";

function AppRoutes() {
  const userDetails = useSelector((state) => state.auth);
  const isSevaEnabled = userDetails?.trustData?.isSevaEnabled;
  const donationViaApp = userDetails?.trustData?.donationViaApp;

  return (
    <Routes>
      <Route path="/" element={<IntroPage />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/galleries" element={<Gallery />} />
      <Route path="/updates" element={<Updates />} />
      <Route path="/events" element={<Event />} />
      <Route path="/notices" element={<Notice />} />

      {/* Conditional Route for /seva */}
      <Route
        path="/seva"
        element={isSevaEnabled ? <SevaList /> : <Navigate to="/" replace />}
      />

      <Route path="/punyarjak" element={<Punyarjak />} />
      <Route path="/detail/:type/:id" element={<CommonDetails />} />
      <Route path="/seva-book/:id" element={<SevaBooking />} />
      <Route path="/booking/detail/:bookingId" element={<BookingDetail />} />
      {/* <Route path="/login" element={<Login />} /> */}
      <Route
        path="/login"
        element={
          !isSevaEnabled && donationViaApp ? (
            <Navigate to="/" replace />
          ) : (
            <Login />
          )
        }
      />
      <Route path="*" element={<NotFound />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-conditions" element={<TermsConditions />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
    </Routes>
  );
}

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
